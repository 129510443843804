import React, { useState, useEffect } from "react";
import image1 from "../assets/img/Cogniglob2.png";
import image2 from "../images/Cogniwhitere.png";
import image3 from "../images/cogniwhite.png";
import { getColorMode, toggleColorMode, applyColorMode } from "../ColorMode";
import firebase from 'firebase/compat/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faChevronDown, faSun, faMoon } from '@fortawesome/free-solid-svg-icons'; // Import faChevronDown for dropdown icon
import "./Navbar.css";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
 
  const [headerClass, setHeaderClass] = useState("");
  const [headerClass1, setHeaderClass1] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [colorMode, setColorMode] = useState(getColorMode());

  const colorvar = getColorMode();
  const var1 = "-text";
  const var2 = colorvar.concat(var1);

  const var3 = "-header-area";
  const var4 = colorvar.concat(var3);

  const var5 = "-background-header";
  const var6 = colorvar.concat(var5);

  const var7 = "-mobile-nav";
  const var8= colorvar.concat(var7);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const updateHeaderClass = () => {
      if (window.scrollY >= 10) {
        setHeaderClass(`${var6} background-header header-reduced`);
      } else {
        setHeaderClass(location.pathname === "/" ? `navbarheadtitle` : ``);
      }
    };
  
   
    updateHeaderClass();
    window.addEventListener("scroll", updateHeaderClass);
  
    return () => window.removeEventListener("scroll", updateHeaderClass);
  }, [location.pathname, var6]); 

  

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 10) {
        setHeaderClass1("");
      } else {
        setHeaderClass1("mobile-iconcolor");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut().then(() => {
      setIsLoggedIn(false);
      navigate("/");
    });
  };
  
  useEffect(() => {
    // Apply the color mode on component mount
    document.body.className = colorMode;
  }, [colorMode]);

  const handleToggleColorMode = () => {
    const newMode = toggleColorMode();
    window.dispatchEvent(new Event('storage'));
    setColorMode(newMode); // Update the color mode state
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); 
  };

  const toggleServiceDropdown = () => {
    setIsServiceDropdownOpen(!isServiceDropdownOpen);
  };

  const [isAdmin, setIsAdmin] = useState(false);

useEffect(()=>{
  const checkAdminStatus=()=>{
  firebase.auth().onAuthStateChanged((user)=>{
    if(user){
      const userEmail=user.email;
      firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
        .then((snapshot)=>{
          if (snapshot.exists()) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }).catch((error)=>{
          console.error("Error checking admin status:",error);
        })
    }else{
      setIsAdmin(false);
    }

  })
  }
  checkAdminStatus();
},[]);


  return (
    <div className={`${var4} header-area wow fadeInDown header-absolate ${headerClass}`}>
      <div className="container">
        <div className="row align-items-center">
          {/* Logo */}
          <div className="col-4 col-lg-2">
            <div className="logo-area">
            <a href="/">
              <img 
                src={(location.pathname === "/" && headerClass === "navbarheadtitle") ? image3 : (colorMode === 'light' ? image1 : image3)} 
                alt="Logo" 
              />
            </a>
            </div>
          </div>

          {/* Mobile Menu Icon (Right-Aligned) */}
          <div className="col-7 d-lg-none text-right">
          
            <button onClick={handleToggleColorMode} className="toggle-color-mode-btn1" style={{paddingRight:"30px"}}>
                  <FontAwesomeIcon icon={colorMode === 'light' ? faSun : faMoon} />
                  </button>
                 
            <FontAwesomeIcon
              icon={faBars}
              className={`mobile-menu-icon`}
              onClick={toggleMobileMenu}
            />
          </div>

          {/* Desktop Navigation */}
          <div className="col-lg-7 d-none d-lg-block">
            <nav className={`${var2} main-menu text-center`}>
              <ul id="slick-nav">
                <li><a className="scroll high1" href="/">Home</a></li>
                <li className="dropdown3">
                  <a className="scroll high3" href="#">About Us</a>
                  <ul className="dropdown-menu2">
                    <li>
                      <a className="navdrop text-black" href="/about">About</a>
                      <a className="navdrop text-black" href="/team">Team</a>
                      <a className="navdrop text-black" href="/blog">Blog</a>
                      <a className="navdrop text-black" href="/casestudies">CaseStudies</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown3">
                  <a className="scroll high3" href="#">Service</a>
                  <ul className="dropdown-menu2">
                    <li><a className="text-black navdrop" href="/service/1">Cloud Migration</a>
                    <a className="text-black navdrop" href="/service/2">Consulting & Implementation</a>
                    <a className="text-black navdrop" href="/service/3">Upgrades</a>
                    <a className="text-black navdrop" href="/service/4">Managed Services</a>
                    <a className="text-black navdrop" href="/service/5">Integrated Services</a>
                    <a className="text-black navdrop" href="/service/6">Product Development</a>
                    <a className="text-black navdrop" href="/service/7">Infrastructure Services</a></li>
                  </ul>
                </li>
                <li><a className="scroll high4" href="/contact">Contact</a></li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-2 text-right d-none d-lg-block">
            {isLoggedIn ? (
              <div className="user-menu">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div id="userDropdown" className="dropdown-menu1 text-center">
                  <a href="/profile">Profile</a>
                  {isAdmin &&<a href="/seo">SEO</a>}
                  <a href="/resume-uploader">Resume</a>
                  {isAdmin &&<a href="/adminpage">AdminPage</a>}
                  {isAdmin &&<a href="/blog-edit">Blog Edit</a>}
                  {isAdmin &&<a href="/casestudies-edit">Case Studies Edit</a>}
                  <a href="#" onClick={handleLogout}>Logout</a>
                </div>
              </div>
            ) : (
              <a href="/login" className="loginbtn gradient-btn">Login</a>
            )}
           
          </div>
          <div className="col-md-1">
            <button onClick={handleToggleColorMode} className="toggle-color-mode-btn">
            <FontAwesomeIcon icon={colorMode === 'light' ? faSun : faMoon} />
            </button></div>
        </div>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className={`${var8} mobile-nav`}>
            <nav>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li>
                  <a href="#" onClick={toggleServiceDropdown}>
                    Services <FontAwesomeIcon icon={faChevronDown} />
                  </a>
                  {isServiceDropdownOpen && (
                    <ul className="dropdown1">
                      <li><a href="/service/1">Cloud Migration</a></li>
                      <li><a href="/service/2">Consulting & Implementation</a></li>
                      <li><a href="/service/3">Upgrades</a></li>
                      <li><a href="/service/4">Managed Services</a></li>
                      <li><a href="/service/5">Integrated Services</a></li>
                      <li><a href="/service/6">Product Development</a></li>
                      <li><a href="/service/7">Infrastructure Services</a></li>
                    </ul>
                  )}
                </li>
                <li><a href="/contact">Contact</a></li>
                <li><a>
               
                  </a>
                </li>
                {isLoggedIn ? (
              <div className="user-menu">
                <FontAwesomeIcon icon={faUser} className="user-icon1" />
                <div id="userDropdown" className="dropdown-menu5">
                  <a href="/profile">Profile</a>
                  {isAdmin &&<a href="/seo">SEO</a>}
                  <a href="/resume-uploader">Resume</a>
                  {isAdmin &&<a href="/adminpage">AdminPage</a>}
                  <a href="#" onClick={handleLogout}>Logout</a>
                </div>
              </div>
            ) : (
              <a href="/login" className="loginbtn gradient-btn">Login</a>
            )}
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;